/* global window */
const efp = (x, y) => window.document.elementFromPoint(x, y);

export const elementInViewport = (el) => {
  if (el !== null) {
    const rect = el.getBoundingClientRect();
    const vWidth = window.innerWidth || window.document.documentElement.clientWidth;
    const vHeight = window.innerHeight || window.document.documentElement.clientHeight;

    // Return false if it's not in the viewport
    if (rect.right < 0 || rect.bottom < 0
      || rect.left > vWidth || rect.top > vHeight) {
      return false;
    }

    // Return true if any of its four corners are visible
    return (
      el.contains(efp(rect.left, rect.top))
      || el.contains(efp(rect.right, rect.top))
      || el.contains(efp(rect.right, rect.bottom))
      || el.contains(efp(rect.left, rect.bottom))
    );
  }

  return false;
};
