import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { elementInViewport } from './elementInViewport';

export const DividerBlock = ({ accentColor, className }) => {
  const dividerRef = useRef(null);

  if (accentColor === null) {
    accentColor = {
      hex: '#988e79',
    };
  }

  const myEfficientFn = () => {
    if (dividerRef.current !== null) {
      if (elementInViewport(dividerRef.current)) {
        dividerRef.current.className = `divider-block in-view ${className}`;
      } else {
        dividerRef.current.className = `divider-block ${className}`;
      }
    }
  };

  /* global window */
  useEffect(() => {
    myEfficientFn();
    window.addEventListener('scroll', myEfficientFn);
  }, []);

  return (
    <div className="divider-block" ref={dividerRef}>
      <span style={{ backgroundColor: `${accentColor.hex}` }} />
    </div>
  );
};

DividerBlock.propTypes = {
  accentColor: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
  className: PropTypes.string,
};

DividerBlock.defaultProps = {
  className: '',
};
